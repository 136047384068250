import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { MDXRenderer } from 'gatsby-plugin-mdx';

require(`katex/dist/katex.min.css`)
require(`./bibtex.css`)

class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <h1 style={{ textAlign: `center` }}>{post.frontmatter.title}</h1>
        <div style={{
          textAlign: `center`,
          marginTop: rhythm(1),
          marginBottom: rhythm(1),
        }}

          dangerouslySetInnerHTML={{ __html: post.frontmatter.authors.replace("Panagiotis Tigas", "<b>Panagiotis Tigas</b>") }} />
        {<MDXRenderer>{post.body}</MDXRenderer>}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PublicationQuery($id: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        authors
        description
      }
    }
  }
`
